<template>
  <div class="modal-shadow saved_popup min-h-screen flex flex-col" v-if="show" @click.self="closeModal">
    <div class="inner flex-col flex p-6">
      <div class="content flex-col flex">
        <div class="w-full flex justify-between">
          <div class="grey_text">
            Assign vehicle(s) to group?
          </div>

          <button class="x_btn" @click="closeModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </div>

        <div class="form_row w-full py-4">
          <div class="relative">
            <select
                v-model="vehicle_group"
                class="block shadow appearance-none border rounded w-full bg-white border-gray-200 text-gray-400 py-2 px-3 pr-8 leading-tight focus:text-gray-700 focus:outline-none focus:shadow-outline"
                id="grid-state-next">
              <option disabled value="">Select...</option>
              <option
                  v-for="(group_vehicles, index) in vehicle_groups"
                  :key="'vehicle-group-apply-' + index"
                  :value="group_vehicles.id">
                {{group_vehicles.name}}
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
          </div>
        </div>

        <div class="pt-6 flex w-full">
          <div class="w-1/2 justify-center">
            <button
                @click="closeModal()"
                class="this_button_grey mr-3 py-4 px-4 border text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom">
              Cancel
            </button>
          </div>

          <div class="w-1/2 justify-center">
            <button
                :disabled="update_disable"
                @click="applyCheck()"
                class="this_button_grey mr-3 py-4 px-4 border"
                :class="update_disable ? 'text-white bg-gray-400 border-gray-400' : 'text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom'">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssignVehiclesToGroup",
  props: {
    vehicle_groups: {
      default: []
    },
    assigned_ids: {
      default: []
    }
  },
  data: function () {
    return {
      show: false,
      update_disable: true,
      vehicle_group: '',
    }
  },
  methods: {
    closeModal: function () {
      this.vehicle_group = '';
      this.update_disable = true;
      this.$emit('assign-close');
      this.show = false
    },
    async applyCheck() {
      let apply = await this.assignApply();

      if (apply) {
        this.$emit('assign-select-success');
      } else {
        this.$emit('assign-select-false');
      }

      this.show = false;
    },
    async assignApply() {
      let data = {
        vehicles_ids: this.assigned_ids,
        group_id: this.vehicle_group
      };

      let result = await this.$http.postAuth(
          `${this.$http.apiUrl()}/vehicle-groups-ref/assign`,
          data
      );

      this.vehicle_group = '';
      this.update_disable = true;

      if (result?.data?.err) {
        console.log(result?.data?.err);
        return false;
      }

      return true;
    }
  },
  watch: {
    vehicle_group(val) {
      if (val && val != '') {
        this.update_disable = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-shadow {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.39);
}
.saved_popup {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  @media screen and (max-width: 800px) {
    .inner {
      min-width: 330px;
    }
  }
}
.inner {
  max-width: 546px;
  width: 50vw;
  margin: auto;
  height: 260px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.inner .content {
  align-items: center;
  gap: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.inner .btn {
  width: 100px;
  height: 40px;
  margin: 0 20px;
  border-radius: 8px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  opacity: 0.75;
}
.inner .btn:hover {
  opacity: 1;
}
.red_text {
  color: #F2374D;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
.grey_text {
  color: #2c3e50;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
.delete_btn {
  background-color: #f2374d;
}
.close_btn {
  background-color: #3d4852;
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
}
.this_button_grey {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
  width: 164px;
}
</style>
