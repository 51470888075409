<template>
  <div class="customers"
       :class="{'page': !isInPopup}"
  >
    <div class="title_line flex justify-between">
      <div class="inline-block flex justify-between items-center"
     :class="{
        'w-full' : isInPopup,
        'w-1/3' : !isInPopup
      }">
        <h1 class="text-left">Trailers</h1>
        <span class="pointer" v-if="isInPopup" @click="closePopup">
          x
        </span>
      </div>

      <div class="inline-block flex w-2/3 justify-end button_group" v-if="!isInPopup">
        <button class="text-white flex items-center button_text in_row_button full_green_button">
          <router-link to="/add-or-edit-trailer/0" class="flex items-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4V19.9996" stroke="white" stroke-width="2" stroke-linecap="round"/>
              <path d="M20 12L4.00038 12" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>

            <span class="h-full">
            New Trailer
          </span>
          </router-link>
        </button>

        <button
            @click="uploadListTrailers"
            class="flex items-center in_row_button square_button square_green_button">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 10L12 15L17 10" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 15V3" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>

        <button
            @click="openDeleteModal"
            :disabled="!show_buttons"
            class="flex items-center last_in_row_button square_button square_red_button">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H8V4Z" stroke="#F2374D" stroke-width="2"/>
            <path d="M3 6H21" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
            <path d="M6.33 20.1867L5 6H19L17.67 20.1867C17.5736 21.2144 16.711 22 15.6787 22H8.32127C7.28902 22 6.42635 21.2144 6.33 20.1867Z" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 18L9.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 18L14.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="filters" v-if="trailers">
      <FiltersDop
          :min_filter="min_filter"
          :names="trailers_names"
          :selected_name="selected_name"
          :items_count="trailers_count"
          :rows_length="trailers.length"
          :rows_count="rows_count"
      ></FiltersDop>
    </div>

    <div class="customers_table fl_table mt-4 px-4"
         :class="{'fl_table': !isInPopup}"
    >
      <div class="wrap" v-if="trailers">
        <!-- table header-->
        <div class="header py-3 px-2 flex text-left justify-between">
          <div class="item flex items-center justify-center w-24px">
            <input v-model="checked_all" type='checkbox'>
          </div>

          <div class="item flex items-center justify-between w-2/12">
            <span class="strong_text">
              Trailer name
            </span>
            <span class="sort"
                  @click="sortItems('name')"
                  :class="{
                    'rotate': ascending && sort_names.name,
                    'active_arrow': sort_names.name
                  }">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.82406 6.80139C5.42671 7.37935 4.57332 7.37936 4.17597 6.80139L0.576992 1.56653C0.120833 0.903028 0.595854 8.78263e-08 1.40103 1.58217e-07L8.59897 -1.66193e-07C9.40415 -9.58021e-08 9.87917 0.903026 9.42302 1.56653L5.82406 6.80139Z" fill="black"/>
              </svg>
            </span>
          </div>

          <div class="item flex items-center justify-between w-2/12">
            <span class="strong_text">
              Trailer ID
            </span>
            <span class="sort"
                  @click="sortItems('trailer_id')"
                  :class="{
                    'rotate': ascending && sort_names.trailer_id,
                    'active_arrow': sort_names.trailer_id
                  }">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.82406 6.80139C5.42671 7.37935 4.57332 7.37936 4.17597 6.80139L0.576992 1.56653C0.120833 0.903028 0.595854 8.78263e-08 1.40103 1.58217e-07L8.59897 -1.66193e-07C9.40415 -9.58021e-08 9.87917 0.903026 9.42302 1.56653L5.82406 6.80139Z" fill="black"/>
              </svg>
            </span>
          </div>

          <div class="item w-2/12">
            QR code
          </div>

          <div class="item w-2/12">
            Height
          </div>

          <div class="item w-2/12">
            Width
          </div>

          <div class="item w-2/12">
            Length
          </div>

          <div class="item delete_column" v-if="!isInPopup">
          </div>
        </div>

        <!-- table body -->
        <div class="body py-2">
          <div
              v-for="(item, index) in trailers"
              :key="index" :class="checked_trailer[index] ? 'checked_row' : ''"
              class="py-5 px-2 flex text-left justify-between">

            <div class="item flex items-center justify-center w-24px">
              <input
                  :id="'checked-input-' + index"
                  v-model="checked_trailer[index]"
                  type='checkbox'>
            </div>

            <div class="item flex items-center justify-start w-2/12" @click="editTrailer(item.id)">
              <div class="green_text icon_div">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
                  <circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </div>
              <div class="green_text pt-1 pl-1">
                {{ item.name }}
              </div>
            </div>

            <div class="item flex items-center justify-start w-2/12">
              {{ item.trailer_id }}
            </div>

            <div class="item flex items-center justify-start w-2/12">
              {{ item.qr_code ? 'ON' : 'OFF' }}
            </div>

            <div class="item flex items-center justify-start w-2/12">
              {{ item.height }}
            </div>

            <div class="item flex items-center justify-start w-2/12">
              {{ item.width }}
            </div>

            <div class="item flex items-center justify-start w-2/12">
              {{ item.length }}
            </div>

            <div class="item flex items-center justify-center w-44px" v-if="!isInPopup">
              <button @click="deleteThisItem(item.id, index)" class="flex items-center justify-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H8V4Z" stroke="#F2374D" stroke-width="2"/>
                  <path d="M3 6H21" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
                  <path d="M6.33 20.1867L5 6H19L17.67 20.1867C17.5736 21.2144 16.711 22 15.6787 22H8.32127C7.28902 22 6.42635 21.2144 6.33 20.1867Z" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 18L9.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 18L14.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="selected_table pb-15 px-4" v-if="isInPopup">
      <div class="top flex justify-between items-center w-full">
        <h3 class="w-1/3">Selected trailers for the Route</h3>
        <div class="filters">
          <FiltersDop
              :min_filter="min_filter"
              :names="trailers_names"
              :selected_name="selected_name"
              :items_count="selectedTrailers.length"
              :rows_length="selectedTrailers.length"
              :rows_count="rows_count"
              :isInPopup="isInPopup"
          ></FiltersDop>
        </div>
      </div>
      <div class="selected_vehicles">
        <div class="header py-3 px-2 flex text-left justify-between">
          <div class="item flex items-center justify-between w-1/12 text-gray-700">
            Trailer name
          </div>
          <div class="item w-1/12">
            Trailer ID
          </div>
          <div class="item w-1/12">
            QR code
          </div>
          <div class="item w-1/12">
            Height
          </div>
          <div class="item w-1/12">
            Width
          </div>
          <div class="item w-1/12">
            Length
          </div>
          <div class="item delete_column" v-if="!isInPopup"></div>
        </div>

        <div class="body py-2">
          <div
              v-for="(trailer, index) in selectedTrailers"
              :key="index" class="py-5 px-2 flex text-left justify-between">
            <div class="item flex items-center justify-between w-1/6">
              <span class="green_text span_cut">
                {{ trailer.name }}
              </span>
            </div>
            <div class="item flex items-center justify-between w-1/6">
              <span class="green_text span_cut">
                {{ trailer.trailer_id }}
              </span>
            </div>
            <div class="item flex items-center justify-between w-1/6">
              <span class="green_text span_cut">
                {{ trailer.qr_code ? 'On' : 'Off' }}
              </span>
            </div>
            <div class="item flex items-center justify-between w-1/12">
              <span class="green_text span_cut">
                {{ trailer.height }}
              </span>
              <span>
                m
              </span>
            </div>
            <div class="item flex items-center justify-between w-1/12">
              <span class="green_text span_cut">
                {{ trailer.width }}
              </span>
              <span>
                m
              </span>
            </div>
            <div class="item flex items-center justify-between w-1/12">
              <span class="green_text span_cut">
                {{ trailer.length }}
              </span>
              <span>
                m
              </span>
            </div>
            <div class="item flex items-center justify-center w-44px w-1/6">
              <button @click="deleteSelectedTrailer(index)" class="flex items-center justify-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H8V4Z" stroke="#F2374D" stroke-width="2"/>
                  <path d="M3 6H21" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
                  <path d="M6.33 20.1867L5 6H19L17.67 20.1867C17.5736 21.2144 16.711 22 15.6787 22H8.32127C7.28902 22 6.42635 21.2144 6.33 20.1867Z" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 18L9.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 18L14.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal
        :entity="'trailer'"
        v-on:delete-select="deleteSelect" v-on:delete-close="closeDelete" ref="delete_modal"/>

    <input id="fileUpload" type="file" accept=".csv" @change="onFileChange" hidden>

  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import FiltersDop from '../../components/FiltersDop';
import {infoMessage} from "../../services/messages";
import DeleteModal from "@/components/DeleteModal";

export default {
  name: "Trailers",
  props: {
    rows_count: {
      type: Number,
      default: 10
    },
    isInPopup: {
      type: Boolean,
      default: false
    },
    selected_trailers_ids: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      min_filter: true,
      sort_names: {
        name: true,
        trailer_id: true,
      },
      trailers_names:[
        {
          name: 'Trailer ID',
          id: 'trailer_id'
        },
        {
          name: 'Trailer Name',
          id: 'trailer_name'
        },
      ],
      selected_name: {name: 'Trailer Name', id: 'name'},
      user: null,
      checked_trailer: [],
      checked_all: false,
      show_buttons: false,
      show_manage: false,
      delete_ids: [],
      file_items: [],
      ascending: false,
      selectedTrailers: [],
      trailers: [],
      trailers_count: null,
      delete_id: null,
    }
  },
  methods: {
    ...mapMutations([
      'setAddTrailers',
      'setSelectedTrailers',
      'setTrailersDimensions'
    ]),
    closePopup() {
      this.setAddTrailers(false); //todo remove it after v2 routing full migration
      this.$emit('closePopup')
    },
    editTrailer(id) {
      this.$router.push('/add-or-edit-trailer/' + id);
    },
    async getUser() {
      this.user = JSON.parse(localStorage.getItem('user')) || null;
    },
    async sortItems(name) {
      const j = this.sort_names;
      this.checked_trailer = [];
      this.checked_all = false;
      Object.keys(j).forEach(sort_name => {
        j[sort_name] = false;
      })
      this.ascending = !this.ascending;
      this.sort_names[name] = true
      await this.getList(name);
    },
    async getList(sortBy = 'name') {
      try {
        const requestData = {
          skip: this.getRowsToShift || 0,
          take: this.getItemsToShow || 5,
          sort: sortBy,
          column: this.getSelectedFilterItem || 'name',
          search: this.getSearchItemsNames || '',
          ascending: this.ascending,
        }

        const response = await this.$http
            .getAuth(`${this.$http.apiUrl()}/trailers?skip=${requestData.skip}&take=${requestData.take}&sort-by=${requestData.sort}&column=${requestData.column}&search=${requestData.search}&sort=${requestData.ascending}`);

        this.trailers = response?.data?.trailers?.data || [];
        this.trailers_count = response?.data?.trailers?.count || null;
        if (this.selected_trailers_ids?.length) {
          this.selectedTrailers = this.trailers.filter(trailer => this.selected_trailers_ids.includes(trailer.id));
          this.checked_trailer = this.trailers.map(trailer => this.selected_trailers_ids.includes(trailer.id));
        }
      } catch (e) {
        console.log(e);
      }
    },
    openDeleteModal() {
      this.$refs.delete_modal.show = true;
    },
    async dropChecked() {
      this.checked_trailer = [];
    },
    async deleteThisItem(id, index) {
      this.delete_id = id;
      await this.dropChecked();
      this.$refs.delete_modal.show = true;
      if (!this.checked_trailer[index]) {
        document.getElementById('checked-input-' + index).click();
      }
    },
    closeDelete() {
      this.delete_id = null;
    },
    async deleteSelect() {
      let deleted;
      if (this.delete_id) {
        deleted = await this.$http.deleteAuth(`${this.$http.apiUrl()}/trailers/${this.delete_id}/delete`);
      } else {
        deleted = await this.$http.postAuth(`${this.$http.apiUrl()}/trailers/multiple-deletion`, {ids: this.delete_ids});
      }

      this.delete_ids = [];
      this.checked_trailer = [];
      if (deleted?.data?.err) {
        console.log(deleted?.data?.err);
        this.delete_id = null;
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      infoMessage(
          this.delete_id ? `Trailer deleted` : `Trailers deleted`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );
      this.delete_id = null;

      await this.getList();
    },
    uploadListTrailers() {
      document.getElementById('fileUpload').click();
    },
    async onFileChange(e) {
      await this.createFile(e.target.files || e.dataTransfer.files);
    },
    async createFile(files) {
      await [...files].forEach(file => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = e => this.file_items.push({
          body: e.target.result,
          name: file.name,
        });
      });
    },
    async deleteSelectedTrailer(index) {
      await this.selectedTrailers.splice(index, 1);
      this.recalcTrailersDimensions();
    },
    async recalcTrailersDimensions() {
      let tDimensions = this.getTrailersDimensions;
      if (this.selectedTrailers.length) {
        let j = this;
        this.selectedTrailers.forEach((veh, index) => {
          tDimensions = j.getTrailersDimensions;
          if (index === 0) {
            tDimensions.height.val = veh.height;
            tDimensions.length.val = veh.length;
            tDimensions.width.val = veh.width;
          } else {
            if (tDimensions.height.val < veh.height) {
              tDimensions.height.val = veh.height;
            }
            if (tDimensions.length.val < veh.length) {
              tDimensions.length.val = veh.length;
            }
            if (tDimensions.width.val < veh.width) {
              tDimensions.width.val = veh.width;
            }
          }
        })
      } else {
        tDimensions.height.val = 4;
        tDimensions.length.val = 12;
        tDimensions.width.val = 2.5;
      }
      this.setSelectedTrailers(this.selectedTrailers)
      await this.$emit('setSelectedTrailersDimensions', {tDimensions, selectedTrailers: this.selectedTrailers})
      return this.setTrailersDimensions(tDimensions);
    },
    async pushList(val) {
      try {
        await this.$http.postAuth(`${this.$http.apiUrl()}/trailers/${this.user.id}/upload-csv`, {files: val});
      } catch (e) {
        console.log(e);
        infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      await this.getList();

      infoMessage(
          `Success. File downloaded.`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );
      this.file_items = [];
    },
  },
  async mounted() {
    await this.getUser();
    await this.getList();
  },
  computed: {
    ...mapGetters([
      'getItemsToShow',
      'getSelectedFilterItem',
      'getRowsToShift',
      'getSearchItemsNames',
      'getTopBanner',
      'getTrailersDimensions',
      'getSelectedTrailers'
    ]),
  },
  components: {
    FiltersDop,
    DeleteModal,
  },
  watch: {
    file_items(val) {
      if (val && val.length) {
        this.pushList(val);
      }
    },
    getItemsToShow() {
      this.getList()
    },
    getSelectedFilterItem() {
      this.getList()
    },
    getRowsToShift() {
      this.getList()
    },
    getSearchItemsNames() {
      this.getList()
    },
    checked_trailer(val) {
      if (this.isInPopup && val) {

        this.recalcTrailersDimensions();

        let j = this;
        j.selectedTrailers = [];
        val.forEach((num, index)  => {
          if (!num) return;
          j.selectedTrailers.push(j.trailers[index])
        })
        this.recalcTrailersDimensions();
        return;
      }
      
      if (this.isInPopup && !val) {
        this.recalcTrailersDimensions();
        return;
      }
      
      if (val) {
        let true_or_false = false;
        for (let key in this.checked_trailer) {
          if (this.checked_trailer[key]) {
            true_or_false = true;
            if (!this.delete_ids.includes(this.trailers[key].id)) {
              this.delete_ids.push(this.trailers[key].id);
            }
          } else {
            if (this.delete_ids.includes(this.trailers[key].id)) {
              for (let x in this.delete_ids) {
                if (this.delete_ids[x] === this.trailers[key].id) {
                  this.delete_ids.splice(x, 1);
                  break;
                }
              }
            }
          }
        }
        this.show_buttons = true_or_false;
      }
    },
    checked_all(val) {
      if (val) {
        for (let key in this.trailers) {
          this.checked_trailer[key] = true;
          this.delete_ids.push(this.trailers[key].id);
        }
        this.show_buttons = true;
      } else {
        for (let key in this.trailers) {
          this.checked_trailer[key] = false;
        }
        this.delete_ids = [];
        this.show_buttons = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.customers {
  min-height: calc(100vh - (147px + 18px));
}
.item {
  font-size: 14px;
  padding-right: 25px;
}
.actions {
  &:hover, &:active, &:focus{
    .hidden {
      display: block;
    }
    span {
      &>* {
        cursor: pointer;
      }
      &:first-child {
        &>*{
          margin-left: auto;
        }
      }
    }
  }
}
.title_line {
  padding: 30px 0 22px;
  border-bottom: none;
}
.filters {
  width: 95%;
  margin: auto;
}
.button_text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
}
.in_row_button {
  margin: 0 15px;
  border-radius: 8px;
}
.last_in_row_button {
  margin-left: 15px;
  border-radius: 8px;
}
.square_button {
  padding: 10px;
  width: 44px;
  height: 44px;
  box-sizing: border-box;
}
.square_green_button {
  color: #1FBC2F;
  border: 2px solid #1FBC2F;
}
.square_red_button {
  color: #F2374D;
  border: 2px solid #F2374D;
  svg {
    path {
      stroke: #F2374D;
    }
  }
}
.square_red_button:disabled {
  color: #3d4852;
  border: 2px solid #3d4852;
  svg {
    path {
      stroke: #3d4852;
    }
  }
}
.strong_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000 !important;
}
.delete_column {
  width: 49px;
}
.item {
  span {
    margin-left: 5px;
    color: #969A9F;
  }
}
.green_text {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #1FBC2F !important;
}
.text_black {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #000000 !important;
}
.span_cut {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: black !important;
}
.checked_row {
  background-color: #FFF1CE;
}
.button_group {
  position: relative;
  .manage_group {
    z-index: 99;
    position: absolute;
    top: 48px;
    right: 0;
    border-radius: 8px;
    box-sizing: border-box;
    border: 0.5px solid #D8C3AF;
    box-shadow: 5px 5px rgba(216, 195, 175, 0.5);
    padding: 10px;
    background-color: #FFFFFF;
    button {
      margin: 10px;
    }
  }
}
.page {
  position: relative;
}
.blocked {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
  z-index: 98;
}
.active_arrow {
  svg {
    path {
      fill: #28a745;
    }
  }
}
.icon_div {
  width: 16px;
  svg {
    width: 100%;
  }
}
</style>
