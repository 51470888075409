<template>
  <div class="modal-shadow saved_popup min-h-screen flex flex-col" v-if="show" @click.self="closeModal">
    <div class="inner flex-col flex p-6">
      <div class="content flex-col flex">
        <div class="w-full flex justify-between">
          <div class="grey_text">
            Remote vehicle(s) from assigned groups
          </div>

          <button class="x_btn" @click="closeModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </div>

        <div class="form_row w-full py-4 text-left text_orange">
          Note: Vehicle(s) will be removed from all assigned vehicle groups
        </div>

        <div class="pt-6 flex w-full">
          <div class="w-1/2 justify-center">
            <button
                @click="closeModal()"
                class="this_button_grey mr-3 py-4 px-4 border text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom">
              Cancel
            </button>
          </div>

          <div class="w-1/2 justify-center">
            <button
                @click="remoteVehicles()"
                class="this_button_grey mr-3 py-4 px-4 border text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom">
              Remote
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RemoveVehiclesFromAssignedGroups",
  props: {
    assigned_ids: {
      default: []
    }
  },
  data: function () {
    return {
      show: false,
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('remote-close');
      this.show = false
    },
    async remoteVehicles() {
      let apply = await this.remoteApply();

      if (apply) {
        this.$emit('remote-select-success');
      } else {
        this.$emit('remote-select-false');
      }

      this.show = false;
    },
    async remoteApply() {
      let data = {
        vehicles_ids: this.assigned_ids,
      };

      let result = await this.$http.postAuth(
          `${this.$http.apiUrl()}/vehicle-groups-ref/remote-assigned`,
          data
      );

      if (result?.data?.err) {
        console.log(result?.data?.err);
        return false;
      }

      return true;
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-shadow {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.39);
}
.saved_popup {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  @media screen and (max-width: 800px) {
    .inner {
      min-width: 330px;
    }
  }
}
.inner {
  max-width: 600px;
  width: 50vw;
  margin: auto;
  height: 260px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.inner .content {
  align-items: center;
  gap: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.inner .btn {
  width: 100px;
  height: 40px;
  margin: 0 20px;
  border-radius: 8px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  opacity: 0.75;
}
.inner .btn:hover {
  opacity: 1;
}
.this_button_grey {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
  width: 164px;
}
.grey_text {
  color: #2c3e50;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
.text_orange {
  color: #ffa800;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
}
</style>
