<template>
  <div class="popup-items-select">
    <Trailers
        :isInPopup="true"
        :rows_count="5"
        @closePopup='closePopup'
        @setSelectedTrailersDimensions='setSelectedTrailersDimensions'
        :selected_trailers_ids="selected_trailers_ids"
    />
  </div>
</template>

<script>

import Trailers from '../views/pages/Trailers'

export default {
  name: "PopupTrailersSelect",
  components: {
    Trailers
  },
  data() {
    return {

    }
  },
  methods: {
    closePopup() {
      this.$emit('closeTrailersPopup')
    },
    setSelectedTrailersDimensions(val) {
      this.$emit('setSelectedTrailersDimensions', val)
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    itemsList: {
      type: Array
    },
    selected_trailers_ids: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
