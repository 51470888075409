<template>
  <div class="popup-items-select">
    <Vehicles
        v-if="!reset_all"
        :isInPopup="true"
        :rows_count="5"
        @closePopup='closePopup'
        @setSelectedVehiclesDimensions='setSelectedVehiclesDimensions'
        :selected_vehicles_ids="selected_vehicles_ids"
    />
  </div>
</template>

<script>

import Vehicles from '../views/pages/Vehicles'

export default {
  name: "PopupVehiclesSelect",
  components: {
    Vehicles
  },
  data() {
    return {

    }
  },
  methods: {
    closePopup(){
      this.$emit('closeVehiclesPopup');
    },
    setSelectedVehiclesDimensions(val) {
      this.$emit('setSelectedVehiclesDimensions', val)
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    itemsList: {
      type: Array
    },
    selected_vehicles_ids: {
      type: Array,
      default: () => []
    },
    reset_all: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
