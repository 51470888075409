<template>
  <div class="component  w-full">
    <div class="flex items-center items-center justify-between mt-4 fw-b px-15">
      <div class="flex alt_top_nav main_menu items-center">
        <div class="p2-p flex items-center">
          <a :href="'/'" class="logo">
            <img v-if="logo_url" :src="logo_url" alt="logo" class="shifted-left-15">
            <img v-else src="@/assets/images/icon.png" alt="logo">
          </a>
          <div class="main-menu px-6">
            <nav class="flex items-center">
              <ul class="menu flex justify-between gap-5">
                <li
                    v-for="(item, index) in menu"
                    :key="index"
                    :class="{'active': $route.path === item.link}"
                ><router-link :to="item.link">{{ item.name }}</router-link></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="flex alt_top_nav user_menu items-center justify-end">
        <div class="flex flex-col p2-p">
          <div class="user flex justify-between gap-5 items-center" @click="dropdown_open = !dropdown_open">
            <div class="icon">
              <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_35_1838)">
                  <rect width="38" height="38" rx="19" fill="#4790E5"/>
                  <g filter="url(#filter0_d_35_1838)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M28 15.5652C28 19.6564 27.4602 24.8687 23.4545 27.0119C22.9563 27.2784 22.6801 27.8722 22.9414 28.3732C24.3109 30.9989 26.5881 32.1041 33.1081 32.8964C33.6139 32.9579 34 33.3855 34 33.895V37C34 37.5523 33.5523 38 33 38H19H5C4.44772 38 4 37.5523 4 37V33.895C4 33.3855 4.38607 32.9579 4.89189 32.8964C11.4119 32.1041 13.6891 30.9989 15.0586 28.3732C15.3199 27.8722 15.0437 27.2784 14.5455 27.0119C10.5398 24.8687 10 19.6564 10 15.5652C10 10.2825 14.0294 6 19 6C23.9706 6 28 10.2825 28 15.5652Z" fill="url(#paint0_linear_35_1838)" shape-rendering="crispEdges"/>
                  </g>
                </g>
                <defs>
                  <filter id="filter0_d_35_1838" x="-2" y="0" width="42" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_35_1838"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_35_1838" result="shape"/>
                  </filter>
                  <linearGradient id="paint0_linear_35_1838" x1="19" y1="6" x2="19" y2="44.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="1" stop-color="white" stop-opacity="0"/>
                  </linearGradient>
                  <clipPath id="clip0_35_1838">
                    <rect width="38" height="38" rx="19" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <span class="username">{{ username }}</span>
            <div class="expander" :class="{'active' : dropdown_open}">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 10L12.7071 15.2929C12.3166 15.6834 11.6834 15.6834 11.2929 15.2929L6 10" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
          <div class="user-menu w-full relative">
            <nav class="dropdown_alt-menu bordered" v-if="dropdown_open">
              <ul class="px-6 pt-5 pb-2 w-full">
                <li
                    v-for="(item, index) in user_menu"
                    :key="index"
                ><router-link :to="item.link">{{ item.name }}</router-link></li>
              </ul>
              <button class="logout bordered_t w-full text-left py-4" @click="logOutHandler">
                <span class="px-6">Logout</span>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <ConfirmLogoutPopup
        @log-out="logOut"
        :alert-title="'Are you sure that you want to log out?'"
    />
    <div class="blocked" v-if="dropdown_open" @click="dropdown_open = false"></div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import ConfirmLogoutPopup from "./ConfirmLogoutPopup";

export default {
  name: "AltHeader",
    data(){
      return {
        logo_url: null,
        menu: [],
        user_menu: [],
        dropdown_open: false,
        user: null,
        username: null
      }
    },
    methods: {
      ...mapMutations([
        'setShowLogoutPopup',
        'logOutUser',
        'setDropState',
      ]),
      getUser() {
        this.user = JSON.parse(localStorage.getItem('user')) || null;
        this.username = this.user?.username || '';
      },
      logoUrl() {
        this.logo_url = `${this.$http.baseUrl()}/` + JSON.parse(localStorage.getItem('supportData')).icon_url || '~@/assets/images/icon.png'
      },
      async logOut() {
        await this.logOutUser();
        await this.setShowLogoutPopup(false);
        await this.$router.push({name: 'Login'})
      },
      logOutHandler() {
        this.setShowLogoutPopup(true)
      },
      userDropdownMenu() {
        if (this.user && !this.user.user_subscription_id) {
          this.user_menu = [
            {
              name: 'ChangePlan',
              link: '/change-plan',
            }]
        } else {
          this.user_menu = this.userMenu;
        }
      }
    },
    async mounted() {
      this.menu = this.mainMenu;
      await this.logoUrl();
      await this.getUser();
      await this.userDropdownMenu();
      await this.setDropState(true);
    },
  computed: {
    ...mapGetters([
      'mainMenu',
      'userMenu',
    ]),
  },
  components: {
    ConfirmLogoutPopup
  },
  watch: {
    dropdown_open(val) {
      this.setDropState(!val);
    }
  }
}
</script>

<style lang="scss" scoped>
  .alt_top_nav {
    background-color: #FFFFFF;
    border-radius: 22px;
  }
  .active {
    background: #FFF1CE;
    border-radius: 5px;
    color: #FFA800;
  }
  .menu {
    li {
      font-size: 16px;
      padding: 7px 12px;
    }
  }
  .blocked {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    height: 100%;
    width: 100%;
    z-index: 489;
  }
  .dropdown_alt-menu {
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 1001;
    float: left;
    max-width: 240px;
    min-width: 175px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    background-clip: padding-box;
  }
  .logo {
    position: relative;
    img {
      position: absolute;
      z-index: 1;
      /* left: 0; */
      right: -8px;
      top: 7px;
    }
  }
  .expander {
    &.active {
      background-color: transparent;
    }
  }
</style>
