<template>
  <div class="modal-shadow saved_popup min-h-screen flex flex-col" v-if="show" @click.self="closeModal">
    <div class="inner">
      <div class="content">
        <div class="w-full flex justify-between modal_header p-6 title_line">
          <div class="modal_title">
            Create vehicle group
          </div>

          <button class="x_btn" @click="closeModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </div>

        <div class="modal_body flex flex-col content-center">
          <div class="w-full px-6">
            <label for="vehicle_group_name" class="block text-gray-700 mb-2 text-left input_label">
              Vehicle group name <span>*</span>
            </label>
            <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="vehicle_group_name"
                @input="toggleDisableButton"
                @click="skipErrors"
                v-model="vehicle_group_name"
                type="text"
                placeholder="Vehicle group name">
            <p v-if="errors.name_required" class="text-red-500 text-xs italic text-left py-2">
              Please fill up necessary field
            </p>
            <p v-if="errors.name_exists" class="text-red-500 text-xs italic text-left py-2">
              Vehicle group name already exist
            </p>
          </div>
        </div>

        <div class="btn_line flex justify-end w-full px-6 modal_footer">
          <button
              @click="createVehicleGroup"
              :disabled="button_disable"
              :class="button_disable ? 'text-white bg-gray-400 border-gray-500' : 'text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom'"
              class="this_button py-3 px-1 border">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateVehicleGroupModal",
  props: [],
  data: function () {
    return {
      show: false,
      user: null,
      button_disable: true,
      errors: {
        name_exists: false,
        name_required: false,
      },
      vehicle_group_name: null,
    }
  },
  methods: {
    toggleDisableButton() {
      if (this.vehicle_group_name.length) {
        this.button_disable = false;
      } else {
        this.button_disable = true;
      }
    },
    async closeModal() {
      this.vehicle_group_name = null;
      await this.skipErrors();
      this.show = false
    },
    async getUser() {
      this.user = JSON.parse(localStorage.getItem('user')) || null;
    },
    async skipErrors() {
      for (let key in this.errors) {
        this.errors[key] = false;
      }
    },
    async createVehicleGroup() {
      await this.skipErrors();

      if (!this.vehicle_group_name || this.vehicle_group_name === '') {
        this.errors.name_required = true;
        this.button_disable = true;
        return;
      }

      const test_name = await this.$http.getAuth(`${this.$http.apiUrl()}/vehicle-groups/${this.vehicle_group_name}/get-by-name`);

      if (test_name?.data?.group) {
        this.errors.name_exists = true;
        this.button_disable = true;
        return;
      }

      await this.getUser();

      let data = {
        name: this.vehicle_group_name,
        user_id: this.user.id
      };

      const response = await this.$http.postAuth(`${this.$http.apiUrl()}/vehicle-groups/create`, data);

      if (response?.data?.group?.name) {
        this.vehicle_group_name = null;
        this.$emit('close-create-vehicle-group-modal-success');
        await this.closeModal();
      } else {
        this.vehicle_group_name = null;
        this.$emit('close-create-vehicle-group-modal-error');
        await this.closeModal();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-shadow {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.39);
}
.modal_header {
  border-radius: 8px 8px 0 0;
  border-bottom: none;
}
.modal_body {
  min-height: 100px;
}
.saved_popup {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  @media screen and (max-width: 800px) {
    .inner {
      min-width: 330px;
    }
  }
}
.inner {
  max-width: 546px;
  width: 50vw;
  margin: auto;
  height: 233px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.inner .content {
  align-items: center;
  gap: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.inner .btn {
  margin: 0 20px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.btn_line {
  border-radius: 0 0 8px 8px;
}
.input_label {
  font-weight: normal;
  font-size: 16px;
  span {
    color: #28a745;
  }
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
  width: 64px;
}
</style>
