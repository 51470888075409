<template>
  <div class="vehicles"
  :class="{'page': !isInPopup}">
    <div class="title_line flex justify-between">
      <div class="inline-block flex justify-between items-center"
        :class="{
        'w-full' : isInPopup,
        'w-1/3' : !isInPopup
      }"
      >
        <h1 class="text-left">Vehicles</h1>
        <span v-if="isInPopup" class="pointer" @click="closePopup()">
          x
        </span>
      </div>

      <div class="inline-block flex w-2/3 justify-end button_group" v-if="!isInPopup">
        <button class="text-white flex items-center button_text in_row_button full_green_button">
          <router-link to="/add-vehicle" class="flex items-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4V19.9996" stroke="white" stroke-width="2" stroke-linecap="round"/>
              <path d="M20 12L4.00038 12" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>

            <span class="h-full">
              Add Vehicle
            </span>
          </router-link>
        </button>

        <button
            @click="uploadListVehicles"
            class="flex items-center in_row_button square_button square_green_button">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 10L12 15L17 10" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 15V3" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>

        <button
            v-if="show_buttons"
            @click="openAssignModal('assign_vehicle_group_modal')"
            class="flex items-center in_row_button square_button square_green_button">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 19V5C1 3.34315 2.34315 2 4 2H7.39445C8.39751 2 9.3342 2.5013 9.8906 3.3359L10.1094 3.6641C10.6658 4.4987 11.6025 5 12.6056 5H21C22.1046 5 23 5.89543 23 7V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19Z" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 13L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17 10" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>

        <button
            v-if="show_buttons"
            @click="openAssignModal('remote_assign_modal')"
            class="flex items-center in_row_button square_button square_green_button">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 19V5C1 3.34315 2.34315 2 4 2H7.39445C8.39751 2 9.3342 2.5013 9.8906 3.3359L10.1094 3.6641C10.6658 4.4987 11.6025 5 12.6056 5H21C22.1046 5 23 5.89543 23 7V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19Z" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 13H16" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>

        <button
            @click="openDeleteModal"
            :disabled="!show_buttons"
            class="flex items-center in_row_button square_button square_red_button">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H8V4Z" stroke="#F2374D" stroke-width="2"/>
            <path d="M3 6H21" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
            <path d="M6.33 20.1867L5 6H19L17.67 20.1867C17.5736 21.2144 16.711 22 15.6787 22H8.32127C7.28902 22 6.42635 21.2144 6.33 20.1867Z" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 18L9.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 18L14.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>

        <button
            @click="show_manage = !show_manage"
            class="flex items-center last_in_row_button square_button square_green_button">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="3.5" r="2.5" fill="#1FBC2F"/>
            <circle cx="12" cy="12" r="2.5" fill="#1FBC2F"/>
            <circle cx="12" cy="20.5" r="2.5" fill="#1FBC2F"/>
          </svg>
        </button>

        <div
            id="manage_group_block"
            v-if="show_manage"
            class="manage_group">
          <button @click="openCreateVehicleGroup" class="block">
            Create vehicle group
          </button>
          <button @click="openManageGroupsModal" class="block">
            Manage vehicle group
          </button>
        </div>
      </div>
    </div>

    <div class="filters">
      <FiltersDop
          :min_filter="min_filter"
          :names="vehicle_names"
          :selected_name="selected_name"
          :items_count="vehicles_count"
          :rows_length="vehicles.length"
          :rows_count="rows_count"
      ></FiltersDop>
    </div>

    <div class="customers_table mt-4 px-4"
      :class="{'fl_table': !isInPopup}"
    >
      <div class="wrap" v-if="vehicles">
        <div class="header py-3 px-2 flex text-left justify-between">
          <div class="item flex items-center justify-center w-24px">
            <input v-model="checked_all" type='checkbox'>
          </div>
          <div class="item flex items-center justify-between w-1/12 ">
            <span class="strong_text "
                  :class="{'span_cut': isInPopup}"
            >
              Vehicle name
            </span>
            <span class="sort"
                  @click="sortItems('name')"
                  :class="{
                    'hidden': !sort_names.name,
                    'rotate': ascending
                  }">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.82406 6.80139C5.42671 7.37935 4.57332 7.37936 4.17597 6.80139L0.576992 1.56653C0.120833 0.903028 0.595854 8.78263e-08 1.40103 1.58217e-07L8.59897 -1.66193e-07C9.40415 -9.58021e-08 9.87917 0.903026 9.42302 1.56653L5.82406 6.80139Z" fill="black"/>
              </svg>
            </span>
          </div>
          <div class="item w-1/12"
            :class="{'span_cut': isInPopup}"
          >
            Group
          </div>
          <div class="item w-3/12"
            :class="{'span_cut': isInPopup}"
          >
            Type
          </div>
          <div class="item w-1/12"
            :class="{'span_cut': isInPopup}"
          >
            Reg. number
          </div>
          <div class="item w-1/12"
            :class="{'span_cut': isInPopup}"
          >
            Make
          </div>
          <div class="item w-1/12"
            :class="{'span_cut': isInPopup}"
          >
            Height
          </div>
          <div class="item w-1/12"
            :class="{'span_cut': isInPopup}"
          >
            Width
          </div>
          <div class="item w-1/12"
            :class="{'span_cut': isInPopup}"
          >
            Length
          </div>
          <div class="item w-1/12"
            :class="{'span_cut': isInPopup}"
          >
            Weight
          </div>
          <div class="item delete_column" v-if="!isInPopup"></div>
        </div>
        <div class="body py-2">
          <div
              v-for="(vehicle, index) in vehicles"
              :key="index" :class="checked_vehicle[index] ? 'checked_row' : ''"
              class="py-5 px-2 flex text-left justify-between">
            <div class="item flex items-center justify-center w-24px">
              <input
                  :id="'checked-input-' + index"
                  v-model="checked_vehicle[index]"
                  type='checkbox'>
            </div>
            <div class="item flex items-center justify-between w-1/12">
              <router-link :to="{path: 'vehicles/' + vehicle.id}" class="green_text span_cut">
                {{ vehicle.name }}
              </router-link>
            </div>
            <div class="item flex items-center justify-between w-1/12">
              <span class="span_cut color_black">
                {{ vehicle.vehicle_groups.length ? vehicle.vehicle_groups[0].name : ''}}
              </span>
            </div>
            <div class="item flex items-center justify-between w-3/12">
              <span class="span_cut color_black">
                {{ vehicle.vehicle_type ? parseTypeByKeys(vehicle.vehicle_type) : '-' }}
              </span>
            </div>
            <div class="item flex items-center justify-between w-1/12 uppercase">
              <span class="span_cut color_black">
                {{ vehicle.registration_number }}
              </span>
            </div>
            <div class="item flex items-center justify-between w-1/12 uppercase">
              <span class="span_cut color_black">
                {{ vehicle.vehicle_make }}
              </span>
            </div>
            <div class="item flex items-center justify-start w-1/12">
              {{ vehicle.height }}
              <span>
                m
              </span>
            </div>
            <div class="item flex items-center justify-start w-1/12">
              {{ vehicle.width }}
              <span>
                m
              </span>
            </div>
            <div class="item flex items-center justify-start w-1/12">
              {{ vehicle.length }}
              <span>
                m
              </span>
            </div>
            <div class="item flex items-center justify-start w-1/12">
              {{ vehicle.weight }}
              <span>
                t
              </span>
            </div>
            <div class="item flex items-center justify-center w-44px" v-if="!isInPopup">
              <button @click="deleteThisVehicle(index)" class="flex items-center justify-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H8V4Z" stroke="#F2374D" stroke-width="2"/>
                  <path d="M3 6H21" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
                  <path d="M6.33 20.1867L5 6H19L17.67 20.1867C17.5736 21.2144 16.711 22 15.6787 22H8.32127C7.28902 22 6.42635 21.2144 6.33 20.1867Z" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 18L9.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 18L14.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="selected_table pb-15 px-4" v-if="isInPopup">
      <div class="top flex justify-between items-center w-full">
        <h3 class="w-1/3">Selected Vehicles for the Route</h3>
        <div class="filters w-2/3">
          <FiltersDop
              :min_filter="min_filter"
              :names="vehicle_names"
              :selected_name="selected_name"
              :items_count="selectedVehicles.length"
              :rows_length="selectedVehicles.length"
              :rows_count="rows_count"
              :isInPopup="isInPopup"
          ></FiltersDop>
        </div>
      </div>
      <div class="selected_vehicles">
        <div class="header py-3 px-2 flex text-left justify-between">
          <div class="item flex items-center justify-between w-1/12 text-gray-700">
            Vehicle name
          </div>
          <div class="span_cut item w-1/12">
            Group
          </div>
          <div class="span_cut item w-3/12">
            Type
          </div>
          <div class="span_cut item w-1/12">
            Reg. number
          </div>
          <div class="span_cut item w-1/12">
            Make
          </div>
          <div class="span_cut item w-1/12">
            Height
          </div>
          <div class="span_cut item w-1/12">
            Width
          </div>
          <div class="span_cut item w-1/12">
            Length
          </div>
          <div class="span_cut item w-1/12">
            Weight
          </div>
          <div class="item delete_column" v-if="!isInPopup"></div>
        </div>

        <div class="body py-2">
          <div
              v-for="(vehicle, index) in selectedVehicles"
              :key="index" class="py-5 px-2 flex text-left justify-between">
            <div class="item flex items-center justify-between w-1/12">
              <span class="green_text span_cut">
                {{ vehicle.name }}
              </span>
            </div>
            <div class="item flex items-center justify-between w-1/12">
              <span class="span_cut color_black">
                {{ vehicle.vehicle_groups.length ? vehicle.vehicle_groups[0].name : '' }}
              </span>
            </div>
            <div class="item flex items-center justify-between w-3/12">
              <span class="span_cut color_black">
                {{ vehicle.vehicle_type ? vehicle.vehicle_type : '-' }}
              </span>
            </div>
            <div class="item flex items-center justify-between w-1/12">
              <span class="span_cut color_black">
                {{ vehicle.registration_number }}
              </span>
            </div>
            <div class="item flex items-center justify-between w-1/12 uppercase">
              <span class="span_cut color_black">
                {{ vehicle.vehicle_make }}
              </span>
            </div>
            <div class="item flex items-center justify-start w-1/12">
              {{ vehicle.height }}
              <span>
                m
              </span>
            </div>
            <div class="item flex items-center justify-start w-1/12">
              {{ vehicle.width }}
              <span>
                m
              </span>
            </div>
            <div class="item flex items-center justify-start w-1/12">
              {{ vehicle.length }}
              <span>
                m
              </span>
            </div>
            <div class="item flex items-center justify-start w-1/12">
              {{ vehicle.weight }}
              <span>
                t
              </span>
            </div>
            <div class="item flex items-center justify-center w-44px">
              <button @click="deleteSelectedVehicle(index)" class="flex items-center justify-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H8V4Z" stroke="#F2374D" stroke-width="2"/>
                  <path d="M3 6H21" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
                  <path d="M6.33 20.1867L5 6H19L17.67 20.1867C17.5736 21.2144 16.711 22 15.6787 22H8.32127C7.28902 22 6.42635 21.2144 6.33 20.1867Z" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 18L9.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 18L14.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <input id="fileUpload" type="file" accept=".csv" @change="onFileChange" hidden>
    <div class="blocked" v-if="show_manage" @click="show_manage = false"></div>
    <DeleteModal v-on:delete-select="deleteSelectVehicle" ref="delete_modal"/>

    <CreateVehicleGroupModal
        v-on:close-create-vehicle-group-modal-success="checkedCloseCreateGroup(true)"
        v-on:close-create-vehicle-group-modal-error="checkedCloseCreateGroup(false)"
        ref="create_vehicle_group_modal"></CreateVehicleGroupModal>

    <AssignVehiclesToGroup
        :vehicle_groups="vehicle_groups"
        :assigned_ids="assigned_ids"
        v-on:assign-select-false="closeAssignModal(false)"
        v-on:assign-select-success="closeAssignModal(true)"
        ref="assign_vehicle_group_modal"></AssignVehiclesToGroup>

    <RemoveVehiclesFromAssignedGroups
        :assigned_ids="assigned_ids"
        v-on:remote-select-success="closeAssignModal(true, 'Removed vehicles from group!')"
        v-on:remote-select-false="closeAssignModal(false)"
        ref="remote_assign_modal"></RemoveVehiclesFromAssignedGroups>

    <MenageVehiclesGroup
        :userId="user ? user.id : 0"
        v-on:manage-close="reloadItems"
        ref="menage_vehicle_groups"></MenageVehiclesGroup>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import FiltersDop from '../../components/FiltersDop';
import DeleteModal from "@/components/DeleteModal";
import CreateVehicleGroupModal from '../../components/CreateVehicleGroupModal';
import AssignVehiclesToGroup from '../../components/AssignVehiclesToGroup';
import RemoveVehiclesFromAssignedGroups from '../../components/RemoveVehiclesFromAssignedGroups';
import MenageVehiclesGroup from '../../components/MenageVehiclesGroup';

import {infoMessage} from "../../services/messages";
import {parseTypeByKeys} from "@/services/helpers";

export default {
  name: "Vehicles",
  props: {
    isInPopup: {
      type: Boolean,
      default: false
    },
    rows_count: {
      type: Number,
      default: 10
    },
    selected_vehicles_ids: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      min_filter: true,
      sort_names: {
        name: true,
      },
      vehicle_names:[
        {
          name: 'Reg. number',
          id: 'registration_number'
        },
        {
          name: 'Vehicle name',
          id: 'name'
        },
        // {
        //   name: 'Vehicle group name',
        //   id: 'group_name'
        // }
      ],
      selected_name: {name: 'Reg. number', id: 'registration_number'},
      user: null,
      text_file: null,

      checked_all: false,
      checked_vehicle: [],
      show_buttons: false,
      show_manage: false,
      delete_ids: [],
      file_items: [],
      ascending: false,
      vehicles: [],
      selectedVehicles: [],
      vehicles_count: null,
      vehicle_groups: [],
      assigned_ids: [],
    }
  },
  methods: {
    parseTypeByKeys,
    ...mapMutations([
      'setVehiclesDimensions',
      'setSelectedVehicles',
      'setAddVehicles'
    ]),
    closePopup() {
      this.setAddVehicles(false); //todo remove it after v2 routing full migration
      this.$emit('closePopup')
    },
    openManageGroupsModal() {
      this.show_manage = false;
      this.$refs.menage_vehicle_groups.show = true;
    },
    async updateAssignedIds() {
      this.assigned_ids = [];
      for (let key in this.checked_vehicle) {
        if (this.checked_vehicle[key]) {
          this.assigned_ids.push(this.vehicles[key].id);
        }
      }
    },
    async openAssignModal(name) {
      if (this.checked_vehicle.length) {
        await this.updateAssignedIds();
      }

      this.$refs[name].show = true;
    },
    async closeAssignModal(val, message = null) {
      this.assigned_ids = [];
      this.checked_vehicle = [];

      if (val) {
        infoMessage(
            message || `Assign vehicles to group!`,
            this.getTopBanner ? 'success open_top_banner' : 'success'
        );
      } else {
        infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      await this.getListVehicles();
    },
    async getUser() {
      this.user = JSON.parse(localStorage.getItem('user')) || null;
    },
    async getVehicleGroup() {
      let vehicle_groups_result = await this.$http.getAuth(`${this.$http.apiUrl()}/vehicle-groups/${this.user.id}/get-by-id`) || [];

      this.vehicle_groups = vehicle_groups_result?.data?.vehicleGroups?.data || [];
    },
    async checkedCloseCreateGroup(val) {
      if (val) {
        infoMessage(
            `Vehicle group created`,
            this.getTopBanner ? 'success open_top_banner' : 'success'
        );
      } else {
        infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      await this.getVehicleGroup();
    },
    openCreateVehicleGroup() {
      this.show_manage = false;
      this.$refs.create_vehicle_group_modal.show = true;
    },
    uploadListVehicles() {
      document.getElementById('fileUpload').click();
    },
    async onFileChange(e) {
      await this.createFile(e.target.files || e.dataTransfer.files);
    },
    async createFile(files) {
      await [...files].forEach(file => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = e => this.file_items.push({
          body: e.target.result,
          name: file.name,
        });
      });
    },
    async pushList(val) {
      try {
        await this.$http.postAuth(`${this.$http.apiUrl()}/vehicles/${this.user.id}/upload-csv`, {files: val});
      } catch (e) {
        console.log(e);
        infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      await this.getListVehicles();

      infoMessage(
          `Success. File downloaded.`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );
      this.file_items = [];
    },
    async sortItems(name) {
      const j = this.sort_names;
      this.checked_vehicle = []
      this.checked_all = false;
      Object.keys(j).forEach(sort_name => {
        j[sort_name] = false;
      })
      this.ascending = !this.ascending;
      this.sort_names[name] = true
      await this.getListVehicles(name)
    },
    async getListVehicles(sortBy = 'name') {
      try {
        const requestData = {
          skip: this.getRowsToShift || 0,
          take: this.getItemsToShow || 5,
          sort: sortBy,
          column: this.getSelectedFilterItem || 'registration_number',
          search: this.getSearchItemsNames || '',
          ascending: this.ascending,
        }

        const response = await this.$http
            .getAuth(`${this.$http.apiUrl()}/vehicles?skip=${requestData.skip}&take=${requestData.take}&sort-by=${requestData.sort}&column=${requestData.column}&search=${requestData.search}&sort=${requestData.ascending}`);

        this.vehicles = response?.data?.vehicles?.data || null;
        this.vehicles_count = response?.data?.vehicles?.count || null;
        if (this.selected_vehicles_ids.length) {
          this.selectedVehicles = this.vehicles.filter(vehicle => this.selected_vehicles_ids.includes(vehicle.id));
          this.assigned_ids = this.selected_vehicles_ids;
          this.checked_vehicle = this.vehicles.map(vehicle => this.selected_vehicles_ids.includes(vehicle.id));
        }
      } catch (e) {
        console.log(e);
      }
    },
    openDeleteModal() {
      this.$refs.delete_modal.show = true;
    },
    async deleteSelectedVehicle(index) {
      await this.selectedVehicles.splice(index, 1);
      this.recalcVehiclesDimensions();
    },
    async deleteSelectVehicle() {
      if (this.checked_vehicle.length) {
        for (let i = 0; i < this.checked_vehicle.length; i++) {
          if (this.checked_vehicle[i] && this.checked_vehicle[i] !== 'undefined') {
            this.delete_ids.push(this.vehicles[i].id);
          }
        }

        await this.dropVehicle();
        this.checked_vehicle = [];

        await this.$http.postAuth(`${this.$http.apiUrl()}/vehicles/multiple-deletion`, {ids: this.delete_ids});
        this.delete_ids = [];

        infoMessage(
            `Vehicle deleted!`,
            this.getTopBanner ? 'success open_top_banner' : 'success'
        );

        await this.getListVehicles();
      }
    },
    dropVehicle() {
      if (this.delete_ids.length) {
        for (let y = 0; y < this.vehicles.length; y++) {
          if (this.delete_ids.includes(this.vehicles[y].id)) {
            this.vehicles.splice(y, 1);
            this.dropVehicle();
          }
        }
      }
    },
    deseleckOtherVehicles(index) {
      for (let key in this.checked_vehicle) {
        if (key != index) {
          document.getElementById('checked-input-' + key).click();
        }
      }
    },
    deleteThisVehicle(index) {
      this.deseleckOtherVehicles(index);
      if (!this.checked_vehicle[index]) {
        document.getElementById('checked-input-' + index).click();
      }

      this.openDeleteModal();
    },
    async recalcVehiclesDimensions() {
      let vDimensions = this.getVehiclesDimensions;
      if (this.selectedVehicles.length) {
      let j = this;
      this.selectedVehicles.forEach((veh, index) => {
        vDimensions = j.getVehiclesDimensions;
        if (index === 0) {
          vDimensions.height.val = veh.height;
          vDimensions.length.val = veh.length;
          vDimensions.width.val = veh.width;
          vDimensions.weight.val = veh.weight;
        } else {
          if (vDimensions.height.val < veh.height) {
            vDimensions.height.val = veh.height;
          }
          if (vDimensions.length.val < veh.length) {
            vDimensions.length.val = veh.length;
          }
          if (vDimensions.width.val < veh.width) {
            vDimensions.width.val = veh.width;
          }
          if (vDimensions.weight.val < veh.weight) {
            vDimensions.weight.val = veh.weight;
          }
        }
      })
      } else {
        vDimensions.height.val = 4;
        vDimensions.length.val = 12;
        vDimensions.width.val = 2.5;
        vDimensions.weight.val = 18;
      }
      this.setSelectedVehicles(this.selectedVehicles)
      await this.$emit('setSelectedVehiclesDimensions', {vDimensions, selectedVehicles: this.selectedVehicles})
      return this.setVehiclesDimensions(vDimensions);
    },
    async reloadItems() {
      this.$router.go();
    }
  },
  async mounted() {
    await this.getUser();
    await this.getVehicleGroup();
    await this.getListVehicles();
  },
  computed: {
    ...mapGetters([
      'getItemsToShow',
      'getSelectedFilterItem',
      'getRowsToShift',
      'getSearchItemsNames',
      'getVehiclesDimensions',
      'getTopBanner',
      'getVehiclesDimensions',
      'getSelectedVehicles'
    ]),
  },
  components: {
    FiltersDop,
    DeleteModal,
    CreateVehicleGroupModal,
    AssignVehiclesToGroup,
    RemoveVehiclesFromAssignedGroups,
    MenageVehiclesGroup
  },
  watch: {
    checked_all(val) {
      if (val) {
        for (let i = 0; i < this.vehicles.length; i++) {
          this.checked_vehicle.push(true);
        }
      } else {
        for (let i = 0; i < this.vehicles.length; i++) {
          this.checked_vehicle[i] = false;
        }
        this.selectedVehicles = []
      }
    },
    checked_vehicle(val) {

      if (this.isInPopup && val) {

        this.recalcVehiclesDimensions();
        val.forEach((num, index)  => {
          if (!num) return;
          if (this.vehicles[index]) {
            const newVehicle = this.vehicles[index];
            if (this.selectedVehicles.find(vehicle => vehicle.id === newVehicle.id)) return;

            this.selectedVehicles.push(newVehicle)
          }
        })
        this.recalcVehiclesDimensions();
        return;
      }

      if (this.isInPopup && !val) {
        this.recalcVehiclesDimensions();
        return;
      }

      for (let z = 0; z < this.checked_vehicle.length; z++) {
        if (this.checked_vehicle[z]) {
          this.show_buttons = true;
          return;
        }
      }
      this.show_buttons = false;
    },
    file_items(val) {
      if (val && val.length) {
        this.pushList(val);
      }
    },
    getItemsToShow() {
      this.getListVehicles()
    },
    getSelectedFilterItem() {
      this.getListVehicles()
    },
    getRowsToShift() {
      this.getListVehicles()
    },
    getSearchItemsNames() {
      this.getListVehicles()
    },
  }
}
</script>

<style lang="scss" scoped>
.item {
  font-size: 14px;
  padding-right: 25px;
}
.actions {
  &:hover, &:active, &:focus{
    .hidden {
      display: block;
    }
    span {
      &>* {
        cursor: pointer;
      }
      &:first-child {
        &>*{
          margin-left: auto;
        }
      }
    }
  }
}
.title_line {
  padding: 30px 0 22px;
  border-bottom: none;
}
.filters {
  width: 95%;
  margin: auto;
}
.button_text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
}
.strong_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000 !important;
}
.delete_column {
  width: 49px;
}
.item {
  font-size: 14px;
  span {
    margin-left: 5px;
    color: #969A9F;
  }
}
.span_cut {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.color_black {
  color: black !important;
}
.checked_row {
  background-color: #FFF1CE;
}
.button_group {
  position: relative;
  .manage_group {
    z-index: 99;
    position: absolute;
    top: 48px;
    right: 0;
    border-radius: 8px;
    box-sizing: border-box;
    border: 0.5px solid #D8C3AF;
    box-shadow: 5px 5px rgba(216, 195, 175, 0.5);
    padding: 10px;
    background-color: #FFFFFF;
    button {
      margin: 10px;
    }
  }
}
.page {
  position: relative;
}
.blocked {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
  z-index: 98;
}
</style>
