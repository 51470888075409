<template>
  <div class="modal-shadow saved_popup min-h-screen flex flex-col" v-if="show" @click.self="closeModal">
    <div class="inner flex-col flex p-6">
      <div class="content flex-col flex">
        <div class="w-full flex justify-between">
          <div class="grey_text">
            Manage vehicle groups
          </div>

          <button class="x_btn" @click="closeModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </div>

        <div class="filters w-full">
          <FiltersDop
              :limit_width="true"
              :names="search_names"
              :selected_name="selected_name"
              :items_count="group_count"
              :rows_length="groups.length"
              :rows_count="rows_count"
          ></FiltersDop>
        </div>

        <div class="customers_table fl_table mt-4 w-full">
          <div class="wrap" v-if="groups">
            <div class="header py-3 px-2 flex text-left justify-between">
              <div class="item flex items-center justify-between w-1/3">
                <span class="strong_text">
                  Group name
                </span>
                <span class="sort"
                      @click="sortItems('name')"
                      :class="{
                        'hidden': !sort_names.name,
                        'rotate': ascending
                      }">
                  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.82406 6.80139C5.42671 7.37935 4.57332 7.37936 4.17597 6.80139L0.576992 1.56653C0.120833 0.903028 0.595854 8.78263e-08 1.40103 1.58217e-07L8.59897 -1.66193e-07C9.40415 -9.58021e-08 9.87917 0.903026 9.42302 1.56653L5.82406 6.80139Z" fill="black"/>
                  </svg>
                </span>
              </div>
              <div class="item flex items-center justify-between w-2/3">
                Assigned vehicles
              </div>
            </div>

            <div class="body py-2">
              <div
                  v-for="(item, index) in groups"
                  :key="'group-item-' + index"
                  class="py-5 px-2 flex text-left justify-between">

                <div class="item flex items-center justify-between w-1/3">
                  <span class="green_text">
                    {{ item.name }}
                  </span>
                </div>
                <div class="item w-2/3">
                  <div
                      v-for="(subItem, subIndex) in item.vehicles"
                      :key="'sub-item-' + subIndex"
                      class="py-5 px-2 flex text-left justify-between w-full">

                    <div class="item flex items-center justify-between w-1/2">
                      <span class="text_strong">
                        {{subItem.name}}
                      </span>
                    </div>

                    <div class="item flex items-center justify-between w-1/2">
                      <span class="text_strong">
                        {{subItem.registration_number}}
                      </span>
                    </div>

                    <div class="item flex items-center justify-center w-44px">
                      <button @click="deAssign(subItem.id)" class="flex items-center justify-center">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H8V4Z" stroke="#F2374D" stroke-width="2"/>
                          <path d="M3 6H21" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
                          <path d="M6.33 20.1867L5 6H19L17.67 20.1867C17.5736 21.2144 16.711 22 15.6787 22H8.32127C7.28902 22 6.42635 21.2144 6.33 20.1867Z" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10 18L9.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M14 18L14.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RemoveVehiclesFromAssignedGroups
            :assigned_ids="assigned_ids"
            v-on:remote-select-success="closeDeleteModal(true)"
            v-on:remote-select-false="closeDeleteModal(false)"
            ref="this_delete_modal"></RemoveVehiclesFromAssignedGroups>

      </div>
    </div>
  </div>
</template>

<script>
import FiltersDop from './FiltersDop';
import {mapGetters, mapMutations} from "vuex";
import RemoveVehiclesFromAssignedGroups from './RemoveVehiclesFromAssignedGroups';
import {infoMessage} from "@/services/messages";

export default {
  name: "MenageVehicleGroup",
  props: {
    rows_count: {
      type: Number,
      default: 10
    },
    userId: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      show: false,
      sort_names: {
        name: true,
      },
      search_names: [
        {
          name: 'Group name',
          id: 'name'
        },
      ],
      selected_name: {name: 'Group name', id: 'name'},
      group_count: null,
      groups: [],
      ascending: false,
      assigned_ids: [],
    }
  },
  methods: {
    ...mapMutations([]),
    closeModal: function () {
      this.$emit('manage-close');
      this.show = false
    },
    deAssign(id) {
      this.assigned_ids.push(id);
      this.$refs.this_delete_modal.show = true;
    },
    async closeDeleteModal (val) {
      this.delete_id = null;

      if (val) {
        infoMessage(
            'Removed vehicle from group!',
            this.getTopBanner ? 'success open_top_banner' : 'success'
        );
      } else {
        infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      await this.getVehicleGroup();
      this.$refs.this_delete_modal.show = false;
    },
    async sortItems(name) {
      const j = this.search_names;
      Object.keys(j).forEach(sort_name => {
        j[sort_name] = false;
      })
      this.ascending = !this.ascending;
      this.search_names[name] = true
      await this.getVehicleGroup(name)
    },
    async getVehicleGroup(sortBy = 'name') {
      try {
        const requestData = {
          skip: this.getRowsToShift || 0,
          take: this.getItemsToShow || 5,
          sort: sortBy,
          column: this.getSelectedFilterItem || 'name',
          search: this.getSearchItemsNames || '',
          ascending: this.ascending,
        }

        const response = await this.$http
            .getAuth(`${this.$http.apiUrl()}/vehicle-groups/${this.userId}/get-by-id-with-vehicles?skip=${requestData.skip}&take=${requestData.take}&sort-by=${requestData.sort}&column=${requestData.column}&search=${requestData.search}&sort=${requestData.ascending}`);

        this.groups = response?.data?.vehicleGroups?.data || null;
        this.group_count = response?.data?.vehicleGroups?.count || null;
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    ...mapGetters([
      'getItemsToShow',
      'getSelectedFilterItem',
      'getRowsToShift',
      'getSearchItemsNames',
      'getTopBanner'
    ]),
  },
  async mounted() {
    await this.getVehicleGroup();
  },
  components: {
    FiltersDop,
    RemoveVehiclesFromAssignedGroups
  },
  watch: {
    getItemsToShow() {
      this.getVehicleGroup()
    },
    getSelectedFilterItem() {
      this.getVehicleGroup()
    },
    getRowsToShift() {
      this.getVehicleGroup()
    },
    getSearchItemsNames() {
      this.getVehicleGroup()
    },
  }
}
</script>

<style lang="scss" scoped>
.item {
  font-size: 14px;
  padding-right: 25px;
}
.modal-shadow {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.39);
}
.saved_popup {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  @media screen and (max-width: 800px) {
    .inner {
      min-width: 330px;
    }
  }
}
.inner {
  max-width: 970px;
  width: 50vw;
  margin: auto;
  min-height: 260px;
  background: #FFFFFF;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.inner .content {
  align-items: center;
  gap: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.inner .btn {
  width: 100px;
  height: 40px;
  margin: 0 20px;
  border-radius: 8px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  opacity: 0.75;
}
.inner .btn:hover {
  opacity: 1;
}
.this_button_grey {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
  width: 164px;
}
.grey_text {
  color: #2c3e50;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
.text_orange {
  color: #ffa800;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
}
.delete_column {
  width: 49px;
}
.strong_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000 !important;
}
.customers_table {
  max-height: 500px;
  overflow-y: scroll;
}
</style>
